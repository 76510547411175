<template>
  <v-container class="general">
    <PageTitle :title="'Roles'" />
    <v-layout class="row wrap">
      <v-flex class="xs12">
        <v-card
          v-if="roles && roles.length"
          class="px-4 py-2"
          style="width: 100%;"
        >
          <v-layout
            v-for="(role, i) in roles"
            :key="`role-${i}`"
            class="row wrap align-center"
          >
            <v-flex class="xs11">
              <span
                ><a @click.stop="editRole(role)" class="theme-primary">
                  {{ role.description }}
                </a></span
              >
            </v-flex>
            <v-flex class="xs1 text-xs-right">
              <v-btn
                icon
                class="theme-primary-bg theme-on-primary"
                @click="deleteRole(role.id)"
                :loading="loading"
              >
                <v-icon small color="important">delete</v-icon>
              </v-btn></v-flex
            >
            <v-flex v-if="showDivider(i)" class="xs12 py-2">
              <v-divider></v-divider>
            </v-flex>
          </v-layout> </v-card
      ></v-flex>
    </v-layout>
    <AppSpeedDial
      @click="$router.push({ name: 'roles_create' })"
      :hint="'Create new role'"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    perPage: 100,
    roles: [],
  }),
  mounted() {
    this.listRoles();
  },
  methods: {
    listRoles() {
      this.$api.roles.list({ per_page: this.perPage }).then((response) => {
        if (!response) return;
        this.roles = response.data.data;
      });
    },
    async deleteRole(id) {
      if (!id) return;

      const role = this.roles.find((el) => el.id === Number(id));

      const confirmed = await this.$refs.confirmationDialog.open(
        `Are you sure you want to delete role \"${role.description}\"?`,
        this.$t("no"),
        this.$t("yes"),
      );

      if (!confirmed) return;

      this.loading = true;

      this.$api.roles.delete(id).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.roles = this.roles.filter((el) => el.id !== id);

          this.$store.dispatch("addNotification", {
            message: `Role \"${role.description}\" deleted`,
          });
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
    showDivider(index) {
      return this.roles.length !== index + 1 ? true : false;
    },
    editRole(model) {
      this.$router
        .push({
          name: "roles_edit",
          params: { role_id: model.id },
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
